import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import React from "react";
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  WithSearch,
  Sorting,
} from "@elastic/react-search-ui";
import {
  BooleanFacet,
  Layout,
  SingleLinksFacet,
  SingleSelectFacet,
} from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { SearchDriverOptions } from "@elastic/search-ui";

const connector = new AppSearchAPIConnector({
  searchKey: "search-op7xwtotgu3on4uq3k6194xb",
  engineName: "goldmine-crawlers-engine",
  endpointBase: "https://937706e1c5e74242992041e269c0212e.ent-search.europe-west4.gcp.elastic-cloud.com",
});

const now = new Date(); // Current date in RFC 3339 format

const oneDayAgo = new Date();
oneDayAgo.setDate(now.getDate() - 1);
const today = oneDayAgo.toISOString(); // Today

const oneWeekAgo = new Date();
oneWeekAgo.setDate(now.getDate() - 7);
const thisWeek = oneWeekAgo.toISOString(); // This Week

const oneMonthAgo = new Date();
oneMonthAgo.setMonth(now.getMonth() - 1);
const thisMonth = oneMonthAgo.toISOString(); // This Month

const oneYearAgo = new Date();
oneYearAgo.setMonth(now.getMonth() - 12);
const thisYear = oneYearAgo.toISOString(); // This Year

const config: SearchDriverOptions = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: connector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      title: { raw: {} },
      "news-content": {
        snippet: {
          fallback: true,
        },
      },
      url: { raw: {} },
      "news-time": { raw: {} },
      "news-time-date": { raw: {} },
      last_crawled_at: { raw: {} },
    },
    search_fields: {
      title: {
        weight: 2,
      },
      "news-content": {
        weight: 5,
      },
    },
    disjunctiveFacets: ["domains"], // Add 'domains' as a disjunctive facet
    facets: {
      "news-time-date": {
        type: "range",
        "ranges": [
          {
            from: today,
            name: "Today",
          },
          {
            from: thisWeek,
            name: "This Week",
          },
          {
            from: thisMonth,
            name: "This Month",
          },
          {
            from: thisYear,
            name: "This Year",
          },
          {
            name: "Older",
            to: thisMonth,
          },
        ],
      },
      "domains": {
        // Add 'domains' facet
        type: "value",
        size: 25, // Show top 10 domains
      },
    },
  },
};

export default function App() {
  return (
    <SearchProvider config={config}>
      <WithSearch
        mapContextToProps={({ wasSearched }) => ({
          wasSearched,
        })}
      >
        {({ wasSearched }) => {
          return (
            <div className="App">
              <ErrorBoundary>
                <Layout
                  header={
                    <SearchBox searchAsYouType={true} debounceLength={1} />
                  }
                  sideContent={wasSearched && (
                    <div>
                      <Sorting
                        label="Sort by"
                        sortOptions={[
                          {
                            name: "Newest",
                            value: "news-time-date",
                            direction: "desc",
                          },
                          {
                            name: "Oldest",
                            value: "news-time-date",
                            direction: "asc",
                          },
                        ]}
                      />
                      <Facet
                        field="news-time-date"
                        label="Time Published"
                      />
                      <Facet
                        field="domains"
                        label="Domains"
                      />
                    </div>
                  )}
                  bodyContent={
                    <Results
                      titleField="title"
                      urlField="url"
                      thumbnailField="image_url"
                      shouldTrackClickThrough
                    />
                  }
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && <PagingInfo />}
                      {wasSearched && <ResultsPerPage />}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}